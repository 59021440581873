<template>
	<div>
		<PopupCommon ref="popupcommon" customClassName="textarea-modal" title=" " :visible.sync="visible"
			>
			<div :slot="'title'" class="pop-title1">{{details.title}}</div>
			<div :slot="'content'" class="pop-content1">
				<div class="content1-title" v-html="details.content">
					
				</div>
				
			</div>
			<div :slot="'footer'" class="pop-footer1">
				<!-- <div class="cancel1" @click="cancel1">{{$t('recommend.cancel')}}</div>
				<div class="confirm1" @click="confirm1">{{$t('recommend.confirm')}}</div> -->
			</div>
		</PopupCommon>
	</div>
</template>

<script>
	import PopupCommon from "@/components/popup/PopupCommon.vue"
	import { agreement } from "@/api/User"
	export default {
		props: {
			// agreeType: {
			// 	type: String,
			// 	default: '1'
			// }
			// details: {
			// 	type: Object,
			// 	default(){
			// 		return {}
			// 	}
			// }
		},
		components: {
			PopupCommon
		},
		data(){
			return {
				visible: false,
				details: {}
			}
		},
		created() {
			// console.log(this.agreeType)
			// this.getAgreement()
		},
		methods: {
			getAgreement(type){
				agreement({
					type: type
				}).then((res) => {
				  console.log('获取协议成功:', res)
				  this.details = res.data
				  // if(type == 1){
				  // 	this.details.title = '用户协议'
				  // }else if(type == 6){
					 //  this.details.title = '隐私政策'
				  // }
				}).catch(err => {
				  console.log('获取协议失败:', err)
				})
			},
			open() {
				this.$refs.popupcommon.open()
			},
			cancel1() {
				this.$refs.popupcommon.close()
			},
			confirm1() {
				this.$refs.popupcommon.close()
			},
			// 前往企业认证
			enterpriseCertification(){
				this.$router.push({
					path: '/personalCenter/accountManagement/enterpriseCertification'
				})
			},
			// 音乐人认证
			musicianCertification(){
				this.$router.push({
					path: '/personalCenter/accountManagement/personInformation'
				})
			},
			// 进行账号充值
			accountRecharging(){
				this.$router.push({
					path: '/personalCenter/accountManagement/accountSecurity'
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	// 权限到期提醒弹窗
	.pop-title1 {
		height: 68px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 0 25px;
		color: @recommendMusicianListTextColor;
	}
	.pop-content1::-webkit-scrollbar{
		display: none;
	}
	.pop-content1 {
		max-height: 242px;
		padding: 24px;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 25px;
		color: @recommendMusicianListTextColor;
		line-height: 22px;
		overflow-y: scroll;
	
		.content1-title {
			font-size: 16px;
		}
	
		.desc-wrap {
			margin-top: 12px;
	
			.desc {
				color: #3370FF;
				cursor: pointer;
			}
		}
	
	}
	
	.pop-footer1 {
		display: flex;
		align-items: center;
		height: 68px;
		justify-content: center;
	
		.cancel1 {
			width: 102px;
			height: 36px;
			border-radius: 4px;
			border: 1px solid @popupCancelBorderColor;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: @popupCancelTextColor;
			margin-right: 44px;
			background: @popupCancelBgColor;
			cursor: pointer;
		}
	
		.confirm1 {
			width: 102px;
			height: 36px;
			background: #3278FF;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: #FFFFFF;
			cursor: pointer;
		}
	}
	
</style>